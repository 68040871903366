import { H3, Image, PortableText, TypeWrapper } from '@ahmdigital/ui';
import classNames from 'classnames';
import React from 'react';

import constants from '../../../../ahm-constants';
import styles from './styles.module.sass';
import TileCta from './cta';

type FeaturesTileProps = {
  image: {
    alt: string;
    src: string;
  };
  summary?: string | unknown[];
  title: string | unknown[];
  urlObject?: {
    name?: string;
    url?: string;
  };
  withSimpleBit?: boolean;
};

const FeaturesTile = ({ image, summary, title, urlObject, withSimpleBit = true }: FeaturesTileProps) => {
  const isLifeStageSection = Boolean(urlObject?.url?.startsWith(`${constants.URLS.SALES.REFINE_PRICE}?scale=`));

  return (
    <div className={isLifeStageSection ? styles.lifeStageTileWrapper : styles.wrapper}>
      <TypeWrapper variant="center">
        <div className={classNames({ [styles.lifeStageContentWrapper!]: isLifeStageSection })}>
          {image && <Image alt={image.alt} src={image.src} className={styles.image} />}
          <H3 looksLike={withSimpleBit ? 'h4' : 'h6'} variant={withSimpleBit ? 'simpleBit' : undefined}>
            <PortableText source={title} />
          </H3>
          <PortableText source={summary} />
        </div>
        <TileCta urlObject={urlObject} isLifeStageSection={isLifeStageSection} />
      </TypeWrapper>
    </div>
  );
};

export default FeaturesTile;
