import { find, get, map } from 'lodash/fp';

import { diversifiedHeroData, heroSectionData } from '../../data/index';
import type { CmsBanner, HeroSectionProps } from '../../types';

type CmsBannerContent = {
  banners: CmsBanner[];
  [key: string]: unknown;
};

type BannerProps = {
  heroSectionProps: HeroSectionProps;
  diversifiedHeroProps: HeroSectionProps;
};

const getBannerProps = (content: CmsBannerContent): BannerProps => {
  const bannerContent = get('banners', content);

  const [cmsDefaultBannerContent, cmsDiversifiedBannerContent] = map(
    (identifier) => find((banner) => get('identifier.current', banner) === identifier, bannerContent),
    ['home-default', 'home-diversified'],
  );

  return {
    diversifiedHeroProps: {
      ...diversifiedHeroData,
      ...cmsDiversifiedBannerContent,
    },
    heroSectionProps: {
      ...heroSectionData,
      ...cmsDefaultBannerContent,
    },
  };
};

export default getBannerProps;
