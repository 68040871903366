import { Button, Clickable } from '@ahmdigital/ui';
import React from 'react';

import styles from '../styles.module.sass';

type Props = {
  urlObject?: { name?: string; url?: string };
  isLifeStageSection: boolean;
};
const TileCta = ({ urlObject, isLifeStageSection = false }: Props) => {
  if (!urlObject?.url || !urlObject?.name) return null;

  return isLifeStageSection ? (
    <Button to={urlObject.url} variant={['border', 'white']} className={styles.lifeStageButton}>
      {urlObject.name}
    </Button>
  ) : (
    <Clickable to={urlObject.url} variant={['marginTopSpacer9']}>
      {urlObject.name}
    </Clickable>
  );
};

export default TileCta;
