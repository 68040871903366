import { theme } from '@ahmdigital/ui';
import styled from 'styled-components';

const { media } = theme;

export const DescriptionWrapper = styled.div`
  margin: 0 auto;
  @media (min-width: ${media.sm}) {
    max-width: 35rem;
  }
`;

export const CtaWrapper = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  text-align: center;
`;
