import { Clickable, Image, SpacerWrapper } from '@ahmdigital/ui';
import styled from 'styled-components';

export const StyledClickable = styled(Clickable)`
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.media.xs}) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

export const StyledDiv = styled.div`
  margin: 1rem;
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin: 1rem;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    margin: 2rem 1rem;
  }
`;

export const StyledImage = styled(Image)`
  height: 4.75rem;
  object-fit: contain;
  padding: inherit;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    height: 8.75rem;
  }
`;

export const StyledWrapper = styled(SpacerWrapper)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    align-items: center;
    flex-direction: row;
  }
`;
