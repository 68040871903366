import { flow, map, take } from 'lodash/fp';

import getImageAttributes from '../../../../../cms/utils/get-image-attributes';
import type { ValuePropositionType } from '../../../../../types/sanity';

const getValuePropositionData = flow(
  take(3),
  map(({ name: title, imageObject, summary, urlObject }: ValuePropositionType) => ({
    image: getImageAttributes({ imageObject }),
    summary,
    title,
    urlObject,
  })),
);

export default getValuePropositionData;
