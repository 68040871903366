import constants from '../../../ahm-constants';
import type { HeroSectionProps } from '../types';

export const diversifiedHeroData: HeroSectionProps = {
  urlObject: {
    name: 'Manage your health cover',
    url: constants.URLS.MEMBERS.HOME,
  },
};

export const heroSectionData: HeroSectionProps = {
  urlObject: {
    name: 'Find cover',
    url: constants.URLS.SALES.REFINE_PRICE,
  },
};
