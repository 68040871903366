import { Clickable, Col, H2, Row, Strong } from '@ahmdigital/ui';
import { map } from 'lodash/fp';
import React from 'react';

import { StyledClickable, StyledDiv, StyledImage, StyledImageWrapper, StyledWrapper } from './styles';
import AWARDS from './data/awards';

const calloutLink = AWARDS.callout.to;
const calloutText = AWARDS.callout.text;
const hasCallout = calloutLink && calloutText;

const Awards = (): JSX.Element => (
  <Row isBoxed>
    <Col df={12}>
      <StyledWrapper variant={['marginTopSpacer9', 'marginBottomSpacer9']}>
        <StyledImageWrapper>
          {map(
            ({ alt, imageSrc, label, to }) => (
              <Clickable to={to} analytics={{ label }} aria-label={label} key={label}>
                <StyledImage src={imageSrc} alt={alt} />
              </Clickable>
            ),
            AWARDS.images,
          )}
        </StyledImageWrapper>
        <StyledDiv>
          <H2 looksLike="h3" variant={['simpleBit', hasCallout && 'marginBottomSpacer9']}>
            {AWARDS.blurb}
          </H2>
          {hasCallout && (
            <StyledClickable to={calloutLink} variant={['bold', 'outlineDarkBg']}>
              <Strong>{calloutText}</Strong>
            </StyledClickable>
          )}
        </StyledDiv>
      </StyledWrapper>
    </Col>
  </Row>
);

export default Awards;
