import { Button, Clickable, H3, Image, Markdown, PortableText } from '@ahmdigital/ui';
import { isArray, isEmpty, omit, some } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import logger from '../../../../logging';
import type { TileContent } from '../../types';

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 10fr auto 7fr auto;
  align-items: start;
  grid-gap: 3px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    text-align: left;
    grid-template-columns: auto;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
`;

const StyledImage = styled(Image)`
  height: 120px;
  margin-bottom: 1rem;
  object-fit: contain;
  width: 120px;
`;

const StyledBodyWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    align-items: start;
  }
`;

const StyledClickable = styled(Clickable)`
  margin-bottom: 1.5rem;
`;

const StyledDescription = styled.div`
  word-wrap: normal;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const wrapDescription = (description: string | any[]) =>
  isArray(description) ? <PortableText source={description} /> : <Markdown>{description}</Markdown>;

type HeroSectionTileProps = TileContent & {
  buttonName: string;
};

const HeroSectionTile = (props: HeroSectionTileProps) => {
  const propsToCheck = omit('alt', props);
  if (some(isEmpty, propsToCheck)) {
    logger.getLogger().warn('HeroSectionTile: Missing props: ', propsToCheck);
  }

  const { alt, buttonLink, buttonName, desc, image, link, linkName, title } = props;

  return (
    <StyledDiv>
      {image && (
        <ImageContainer>
          <StyledImage src={image} alt={alt || undefined} role={alt ? undefined : 'presentation'} />
        </ImageContainer>
      )}
      {title && <H3 variant="simpleBit">{title}</H3>}
      <StyledBodyWrapper>
        {desc && <StyledDescription>{wrapDescription(desc)}</StyledDescription>}
        {link && <StyledClickable to={link}>{linkName}</StyledClickable>}
      </StyledBodyWrapper>
      {buttonLink && (
        <StyledButton to={buttonLink} variant={['transparentBlack', 'border']}>
          {buttonName}
        </StyledButton>
      )}
    </StyledDiv>
  );
};

export default HeroSectionTile;
