import { compact, isEmpty, map } from 'lodash/fp';
import { IconList, PortableText, theme } from '@ahmdigital/ui';
import generatePortableText from '@ahmdigital/logic/lib/content/utils/generate-portable-text';
import React from 'react';
import styled from 'styled-components';

import type { Ends, Subtext, Value } from '../../../../../../components/offers/tile/utils/get-content-for-offer';

const PortableTextForList = styled(PortableText)`
  margin-left: 0.375rem;
  @media (min-width: ${theme.media.sm}) {
    max-width: 47vw; // This prevents overlap with the hero background image
  }
`;
const PortableTextForTerms = styled(PortableTextForList)`
  margin-top: 0.375rem;
`;

const subtextToListItems = (subtextValue: Value) => {
  if (isEmpty(subtextValue)) return null;
  return {
    fill: theme.colors.green,
    icon: 'tick-circle',
    text: <PortableTextForList source={generatePortableText({ value: subtextValue })} />,
  };
};

type RewardsListProps = {
  sublist: Subtext | null;
  ends: Ends | null;
};

const RewardsList = ({ sublist, ends }: RewardsListProps) => (
  <>
    {sublist && <IconList items={compact(map(subtextToListItems, sublist.value))} variant="simple" />}
    {ends && <PortableTextForTerms source={generatePortableText(ends)} />}
  </>
);

export default RewardsList;
