// @ts-expect-error - Automatic, Please fix when editing this file
const getSanityUri = (config) => {
  const sanityConfig = config.get('sanity');
  const FILTERED_SUFFIX = '_filtered';
  const dataset = `${sanityConfig.dataset}${sanityConfig.useFiltered ? FILTERED_SUFFIX : ''}`;
  return `https://${sanityConfig.projectId}.api${sanityConfig.useCdn ? 'cdn' : ''}.sanity.io/v1/graphql/${dataset}/${
    sanityConfig.tag
  }`;
};

module.exports = getSanityUri;
