import { Col, Section } from '@ahmdigital/ui';
import styled from 'styled-components';

export const StyledSection = styled(Section)`
  padding: 0.5rem 0 3.5rem;

  ${({ theme, hasOffer, hasSubtitle }) => `
    @media (min-width: ${theme.media.xs}) {
      max-width: 31.25rem;
    }

    @media (min-width: ${theme.media.sm}) {
      max-width: 55rem;
      padding: 13.75rem 2rem 16rem;

      ${
        hasSubtitle &&
        `
        padding: 0.5rem 2rem 11rem;
      `
      };

      ${
        hasOffer &&
        `
        padding: 0.5rem 2rem 6rem;
      `
      };
    }

    @media (min-width: ${theme.media.md}) {
      max-width: 55rem;
      padding: 13rem 2rem 11.5rem;

      ${
        hasSubtitle &&
        `
        padding: 10rem 2rem;
      `
      };

      ${
        hasOffer &&
        `
        padding: 6rem 2rem;
      `
      };
    }
  `}

  .flair {
    .ctaLink {
      color: $color-purple;
    }
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    @media (min-width: ${theme.media.md}) {
      flex-direction: row;
    }
  `}
`;

export const DesktopVideoWrapper = styled.div`
  margin-top: 13rem;
  display: none;

  ${({ theme }) => `
    @media (min-width: ${theme.media.md}) {
      display: block;
      text-align: center;
      width: 100%;
    }
  `}
`;

export const MobileVideoWrapper = styled.div`
  margin: 3rem auto 2rem;
  width: fit-content;
  display: block;

  ${({ theme }) => `
    @media (min-width: ${theme.media.md}) {
      display: none;
    }
  `}
`;
