import { Button, Col, H2, PortableText, Row, SpacerWrapper, TypeWrapper } from '@ahmdigital/ui';
import { compact, kebabCase, map, size } from 'lodash/fp';
import React from 'react';

import { CtaWrapper, DescriptionWrapper } from './styles';
import { PortableTextType } from '../../../types/sanityInternal';
import FeaturesTile from './tile';
import uncapFpIterator from '../../../../utils/uncap-fp-iterator';
import WrappedSection from '../wrapped-section';

export type FeatureSectionTile = {
  cta?: {
    title: string;
    to: string;
  } | null;
  image: {
    alt: string;
    src: string;
  };
  summary: PortableTextType[] | string;
  title: PortableTextType[] | string;
};

type FeaturesSectionProps = {
  cta?: {
    onClick?: (...args: unknown[]) => unknown;
    title: string | {};
    to?: string;
  };
  description?: PortableTextType[] | string;
  title: string | JSX.Element;
  tiles: FeatureSectionTile[];
  type?: string;
  withBorder?: boolean;
  withSimpleBit?: boolean;
};

const FeaturesSection = ({
  cta,
  description,
  title,
  tiles,
  withBorder = false,
  withSimpleBit = true,
}: FeaturesSectionProps) => {
  const numberOfTiles = size(tiles);

  return (
    <WrappedSection withBorder={withBorder}>
      <TypeWrapper variant={['center', 'marginBottomSpacer7']}>
        <H2 variant={compact([withSimpleBit && 'simpleBit'])}>{title}</H2>
        {description && (
          <DescriptionWrapper>
            <PortableText source={description} />
          </DescriptionWrapper>
        )}
      </TypeWrapper>
      <Row lgHorizontalAlign="center">
        {uncapFpIterator(map)(
          (tile: FeatureSectionTile, index: number) => (
            <Col df={numberOfTiles === 4 ? 3 : 4} md={12} variant="marginBottomSpacer10">
              <FeaturesTile {...tile} key={`${kebabCase(tile.image?.alt ?? '')}-${index}`} withSimpleBit />
            </Col>
          ),
          tiles,
        )}
      </Row>
      {cta && (cta.onClick || cta.to) && (
        <SpacerWrapper variant={['marginTopSpacer8', 'marginBottomSpacer11']}>
          <CtaWrapper>
            <Button onClick={cta.onClick} to={cta.to} variant={['border', 'white']}>
              {cta.title}
            </Button>
          </CtaWrapper>
        </SpacerWrapper>
      )}
    </WrappedSection>
  );
};

export default FeaturesSection;
