import { filter, get, includes } from 'lodash/fp';

import type { CmsBanner } from '../../src/client/pages/home/types';

const filterHomePageBanners = (cmsBanners: CmsBanner[]): CmsBanner[] =>
  filter(
    (banner) => includes(get('identifier.current', banner), ['home-default', 'home-diversified']),
    get('allBanner', cmsBanners),
  );

export default filterHomePageBanners;
