import { get, head, isEmpty } from 'lodash/fp';

import { FRAGMENTS } from '../../../src/client/models/table-of-contents/constants';
import getCmsData from '../../../src/client/cms/utils/get-cms-data';
import getTableOfContentsListByIdentifierQuery from '../../../src/client/models/table-of-contents/utils/get-table-of-contents-list-by-identifier-query';

const getWhyAhmQuery = async () => {
  const query = getTableOfContentsListByIdentifierQuery(FRAGMENTS.WHY_AHM);
  const cmsWhyAhmData = await getCmsData(query, {
    variables: { identifier: 'why-ahm' },
  });
  const WhyAhmItems = get('items', head(get('allTableOfContentsList', cmsWhyAhmData)));

  return !isEmpty(WhyAhmItems) ? WhyAhmItems : null;
};

export default getWhyAhmQuery;
