import { isEmpty } from 'lodash/fp';

import { ValueProposition } from '../../../../types/__codegen__/sanity/graphql';
import getValuePropositionData from '../../../explained-term/detail/utils/get-value-propositon-data';

type WhyAhmSectionProps = {
  item?: {
    name: string;
    valuePropositionsReference: {
      item: ValueProposition[];
    };
  };
};

const getWhyAhmSection = (valuePropositionGroups: WhyAhmSectionProps[]) => {
  if (isEmpty(valuePropositionGroups)) {
    return {};
  }
  const whyAhmSectionGroup = valuePropositionGroups[0];
  if (!whyAhmSectionGroup) {
    return {};
  }
  const { item } = whyAhmSectionGroup;
  const whyAhmSectionTitle = item?.name;
  const whyAhmTileData = getValuePropositionData(item?.valuePropositionsReference);
  return { whyAhmSectionTitle, whyAhmTileData };
};

export default getWhyAhmSection;
