import { groupBy, head, includes } from 'lodash/fp';

import { Offer } from '../../components/offers/types';
import constants from '../../ahm-constants';

const EXTRAS_ONLY_OFFER_TYPE = 'extras-only';
const HOSPITAL_ONLY_OFFER_TYPE = 'hospital-only';

const filterOffersByProductType = (lastViewedProductType: string, offers: Offer[]) => {
  const { extrasOnlyOffers, hospitalOnlyOffers, otherOffers } = groupBy((offer: Offer) => {
    const offerId = offer?.identifier?.current;
    if (includes(EXTRAS_ONLY_OFFER_TYPE, offerId)) {
      return 'extrasOnlyOffers';
    }
    if (includes(HOSPITAL_ONLY_OFFER_TYPE, offerId)) {
      return 'hospitalOnlyOffers';
    }

    return 'otherOffers';
  })(offers);

  const prioritisedOffersForProductType = () => {
    if (lastViewedProductType === constants.PRODUCT_TYPE.EXTRAS) {
      return extrasOnlyOffers;
    }
    if (lastViewedProductType === constants.PRODUCT_TYPE.HOSPITAL) {
      return hospitalOnlyOffers;
    }
    return otherOffers;
  };

  const offerPriorityWhenNoProductTypeMatch = otherOffers || extrasOnlyOffers || hospitalOnlyOffers;
  return head(prioritisedOffersForProductType()) || head(offerPriorityWhenNoProductTypeMatch);
};

export default filterOffersByProductType;
