// @ts-ignore - Automatic, Please fix when editing this file
const { ApolloClient, HttpLink } = require('@apollo/client');
// @ts-expect-error - Automatic, Please fix when editing this file
const { get } = require('lodash/fp');
const { InMemoryCache } = require('@apollo/client/cache');
// @ts-expect-error - Automatic, Please fix when editing this file
const fetch = require('node-fetch');

// This creates the CMS client for the server side logic and CMS interactions, with the client having a similar copy of this
// code. Any changes done to this file needs to be replicated on the server's CMS client as well (particularly the createClient
// and getUri methods). These would most probably get combined when Next.js is implemented.
// @ts-expect-error - Automatic, Please fix when editing this file
const createClient = (config, dataset, mode, uri) => {
  const sanityConfig = config.get('sanity');
  const cache = new InMemoryCache();

  const fetchPolicy = sanityConfig.fetchPolicy.server;
  const opts = {
    cache,
    connectToDevTools: get('NODE_ENV')(config) !== 'production',
    defaultOptions: {
      query: {
        fetchPolicy,
      },
      watchQuery: {
        fetchPolicy,
      },
    },
    link: new HttpLink({
      fetch,
      uri,
    }),
    name: 'sanity-sales',
    ssrMode: true,
  };
  return new ApolloClient(opts);
};

module.exports = createClient;
