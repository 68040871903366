import { Col, ContentTile, Row } from '@ahmdigital/ui';
import { get, head, map } from 'lodash/fp';
import React from 'react';

import { TILE_VARIANTS } from '../../../../ahm-constants/ui';
import mapContent, { type ItemType } from './utils/map-content';
import sortTiles from '../../../../utils/sort-tiles';

export type ContentTilesProps = {
  items: {
    item: ItemType;
    name?: string;
    release?: {
      endTime: string;
      startTime: string;
    };
  }[];
};

const ContentTiles = ({ items }: ContentTilesProps) => {
  const tiles = mapContent(map('item', items));
  const sortedChunkedTiles = sortTiles(tiles, TILE_VARIANTS.VERTICAL);

  return (
    <>
      {map(
        (chunk) => (
          <Row isBoxed key={get('_id', head(chunk))}>
            {map(
              (tile) => (
                <Col key={get('_id', tile)} df md={12 / chunk.length} sm={12}>
                  <ContentTile {...tile} />
                </Col>
              ),
              chunk,
            )}
          </Row>
        ),
        sortedChunkedTiles,
      )}
    </>
  );
};

export default ContentTiles;
