import { get } from 'lodash/fp';
import type { DocumentNode } from 'graphql';
import type { OperationVariables, QueryHookOptions } from '@apollo/client';

import config from '../../../config';
// @ts-expect-error - Automatic, Please fix when editing this file
import createClient from '../../../utils/create-apollo-client';
// @ts-expect-error - Automatic, Please fix when editing this file
import getSanityUri from '../../../utils/get-sanity-uri';

const getCmsData = async (query: DocumentNode, options?: QueryHookOptions<any, OperationVariables>) => {
  const variables = get('variables', options);
  const sanityConfig = config.get('sanity');
  const sanityClient = createClient(config, sanityConfig.dataset, 'published', getSanityUri(config));
  const result = await sanityClient.query({
    query,
    variables,
  });

  return result.data;
};

export default getCmsData;
