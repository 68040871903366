import { BorderGridContainer, GridBox, Section } from '@ahmdigital/ui';
import classNames from 'classnames';
import React from 'react';

import { VariantType } from '../../../types/variant';
import styles from './styles.module.sass';

type WrappedSectionProps = {
  children: React.ReactNode;
  spacerSize?: number | 'None';
  variant?: VariantType;
  withBorder?: boolean;
  withInvert?: boolean;
  withWide?: boolean;
};

const WrappedSection = ({ children, variant, withBorder, withInvert, withWide, spacerSize }: WrappedSectionProps) => {
  const section = (
    <Section variant={[`paddingTopSpacer${spacerSize}`, `paddingBottomSpacer${spacerSize}`, styles.section, variant]}>
      {children}
    </Section>
  );
  return withBorder ? (
    <BorderGridContainer>
      <GridBox padding="0" backgroundColor={withInvert ? 'black' : 'white'}>
        <div
          className={classNames([
            styles.horizontalSpacingWrapper,
            withInvert ? styles.invert : null,
            withWide ? styles.wide : null,
          ])}
        >
          {section}
        </div>
      </GridBox>
    </BorderGridContainer>
  ) : (
    section
  );
};

WrappedSection.defaultProps = {
  spacerSize: 3,
  variant: null,
  withBorder: false,
  withInvert: false,
  withWide: false,
};

export default WrappedSection;
