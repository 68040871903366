import React from 'react';

import { FRAGMENTS } from '../src/client/models/table-of-contents/constants';
import { GET_ALL_BANNER_QUERY } from '../src/client/cms/queries';
import { GrowthBookPageWrapper, injectGrowthBookProps } from '../utils/growth-book';
import { STATIC_BUILD_REVALIDATE_TIME } from '../src/client/ahm-constants/ui';
import { ValueProposition } from '../src/client/types/__codegen__/sanity/graphql';
import filterHomePageBanners from './utils/filter-home-page-banners';
import getAnnouncementsQuery from './utils/get-announcements-query';
import getCmsData from '../src/client/cms/utils/get-cms-data';
import getOffersContent from './utils/get-offers-content';
import getShouldSkipClientSideRequest from '../src/client/utils/get-should-skip-client-side-request';
import getWhyAhmQuery from './utils/get-why-ahm-query';
import HomePageComponent from '../src/client/pages/home';
import useGetHomeBannersQuery from './utils/use-get-home-banners-query';
import useGetOffersContent from './utils/use-get-offers-content';
import useGetTableOfContentsLists from './utils/use-get-table-of-contents-lists';
import type { CmsBanner } from '../src/client/pages/home/types';
import type { ContentTilesProps } from '../src/client/pages/home/components/content-tiles';
import type { Offer, Placeholder } from '../src/client/components/offers/types';

type HomeProps = {
  activeOffers: Offer[];
  announcements: ContentTilesProps['items'];
  banners: CmsBanner[];
  placeholders: Placeholder[];
  valueProposition: {
    item: {
      name: string;
      valuePropositionsReference: {
        item: ValueProposition[];
      };
    };
  }[];
};

const HomePage = ({
  activeOffers: serverSideOffers,
  announcements: serverSideAnnouncements,
  banners: serverSideBanners,
  placeholders: serverSidePlaceholders,
  valueProposition: serverSideValueProposition,
}: HomeProps) => {
  const shouldSkip = getShouldSkipClientSideRequest();
  const { banners, loading: isBannersLoading } = useGetHomeBannersQuery({ shouldSkip });

  const { data: announcements, loading: isAnnouncementsLoading } = useGetTableOfContentsLists({
    fragment: FRAGMENTS.ANNOUNCEMENTS,
    shouldSkip,
    slug: 'announcements',
  });

  const { data: valueProposition, loading: isValuePropositionLoading } = useGetTableOfContentsLists({
    fragment: FRAGMENTS.WHY_AHM,
    shouldSkip,
    slug: 'why-ahm',
  });

  const { activeOffers, placeholders, loading: isOfferContentLoading } = useGetOffersContent({ shouldSkip });

  const content = {
    activeOffers: shouldSkip || isOfferContentLoading ? serverSideOffers : activeOffers,
    announcements: shouldSkip || isAnnouncementsLoading ? serverSideAnnouncements : announcements,
    banners: shouldSkip || isBannersLoading ? serverSideBanners : banners,
    placeholders: shouldSkip || isOfferContentLoading ? serverSidePlaceholders : placeholders,
    valueProposition: shouldSkip || isValuePropositionLoading ? serverSideValueProposition : valueProposition,
  };

  return <HomePageComponent content={content} />;
};

export const getStaticProps = async () => {
  const [cmsBannerData, announcements, { activeOffers, placeholders }, cmsTableOfContentsData] = await Promise.all([
    getCmsData(GET_ALL_BANNER_QUERY),
    getAnnouncementsQuery(),
    getOffersContent(),
    getWhyAhmQuery(),
  ]);

  const cmsHomePageBannerContent = filterHomePageBanners(cmsBannerData);

  const payload = await injectGrowthBookProps({
    // The resolved url is unknown during SSG or ISR (getStaticProps),
    // since during the build the baseURL is unknowable (eg sales-dev01.ahm.ninja).
    // Targeting on URL can only be used client-side or server-side (SSR).
    context: { resolvedUrl: null },
    props: {
      activeOffers,
      announcements,
      banners: cmsHomePageBannerContent || null,
      placeholders,
      valueProposition: cmsTableOfContentsData,
    },
  });

  return {
    ...payload,
    revalidate: STATIC_BUILD_REVALIDATE_TIME,
  };
};

// @ts-expect-error - Automatic, Please fix when editing this file
export default function WrappedPage(props) {
  // @ts-expect-error - Automatic, Please fix when editing this file
  return GrowthBookPageWrapper(props, HomePage);
}
