import { Button, Col, H1, H2, Row } from '@ahmdigital/ui';
import { get, isNil } from 'lodash/fp';
import React from 'react';

import { DesktopVideoWrapper, MobileVideoWrapper, StyledCol, StyledSection } from './styles';
import { SANITY_MEDIA_FILES } from '../../../../ahm-constants/ui';
import type { HeroSectionProps } from '../../types';
import type { UrlObjectType } from '../../../../types/sanity';

const getCtaComponent = (urlObject: UrlObjectType) => {
  const { url, name } = urlObject;
  return (
    <Button
      icon={{
        name: 'arrow-right-long',
        position: 'right',
        size: 'large',
      }}
      to={url}
      variant={['flair', 'wide', 'wideSpaceBetween']}
    >
      {name}
    </Button>
  );
};

const HeroSection = ({ heroOfferSection, name, subtitle, urlObject }: HeroSectionProps) => {
  const hasSubtitle = !isNil(subtitle);
  const activeOffers = get('props.activeOffers', heroOfferSection);
  const hasOffer = !isNil(activeOffers);

  return (
    <Row isBoxed>
      <StyledCol df>
        <MobileVideoWrapper>
          <video muted autoPlay height="280px">
            <source src={SANITY_MEDIA_FILES.GLITCH_VIDEO} type="video/mp4" />
          </video>
        </MobileVideoWrapper>
        <StyledSection hasOffer={hasOffer} hasSubtitle={hasSubtitle}>
          <H1>{name}</H1>
          {hasSubtitle && (
            <H2 looksLike="h6" variant={['regular']}>
              {subtitle}
            </H2>
          )}
          {heroOfferSection}
          <Row>
            <Col sm={12} md={6}>
              {/* @ts-expect-error - Automatic, Please fix when editing this file */}
              {getCtaComponent(urlObject)}
            </Col>
          </Row>
        </StyledSection>
        <DesktopVideoWrapper>
          <video muted autoPlay height="400px">
            <source src={SANITY_MEDIA_FILES.GLITCH_VIDEO} type="video/mp4" />
          </video>
        </DesktopVideoWrapper>
      </StyledCol>
    </Row>
  );
};

export default HeroSection;
