import { Col, H1, P, ProductTile, Row, Section, Strong } from '@ahmdigital/ui';
import { connect } from 'react-redux';
import { get, omit } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import { Customer } from '../../../../selectors/select-customer/types';
import { Prices } from '../../../../models/price';
import { RootState } from '../../../../reducers';
import BackgroundImage from '../hero-section/background-image';
import confettiLargeImage from './images/confetti-large.png';
import confettiSmallImage from './images/confetti-small.png';
import constants from '../../../../ahm-constants';
import HeroPriceDisclaimer from '../hero-price-disclaimer';
import prepareProductForProductTile from '../../../../utils/prepare-product-for-product-tile';
import ProductPropType from '../../../../types/product';
import selectCustomer from '../../../../selectors/select-customer';

type WelcomeBackHeroSectionProps = {
  customer: Customer;
  heroOfferSection: JSX.Element;
  isLoadingPrice: boolean;
  lastViewedProduct?: ProductPropType;
  prices: Prices;
  useFutureEffectiveDate: boolean;
};

const StyledRow = styled(Row)`
  ${({ theme }) => `
    @media (min-width: ${theme.media.md}) {
      padding-left: 2rem;
      padding-right: 1rem;
    }
  `}
`;

const CardWrapper = styled.div`
  width: 311px;
  background-color: white;
  margin-top: 3.5rem;
`;

const CardHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeadingCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  ${({ mobile, theme }) => `
    background-image: url('${mobile?.image}');
    background-position: ${mobile?.position};
    background-size: auto 80%;

    @media (min-width: ${theme.media.sm}) {
      background-size: auto 90%;
    }

    @media (min-width: ${theme.media.md}) {
      background-size: cover;
    }
  `}
`;

const WelcomeBackHeroSection = ({
  customer,
  heroOfferSection,
  isLoadingPrice,
  lastViewedProduct,
  prices,
  useFutureEffectiveDate,
}: WelcomeBackHeroSectionProps) => {
  const productName = get('name', lastViewedProduct);

  const blurb = get('content.longForm', lastViewedProduct);

  const tileProduct = prepareProductForProductTile({
    customer,
    frequency: customer.frequency,
    mlsAmount: undefined,
    prices,
    useFutureEffectiveDate,
  })(lastViewedProduct);

  return (
    <Row isBoxed>
      <Col df={12}>
        <Section>
          <StyledRow>
            <HeadingCol df={6} md={12}>
              <H1>Welcome back!</H1>
              <P looksLike="h4" variant="regular">
                Pick up where you left off with <Strong>{productName}</Strong>
              </P>
              {heroOfferSection}
            </HeadingCol>

            <Col df={6} md={12}>
              <StyledBackgroundImage
                desktop={{
                  image: confettiLargeImage.src,
                  position: 'left -50px top',
                }}
                tablet={{
                  image: confettiSmallImage.src,
                  position: 'top',
                }}
                mobile={{
                  image: confettiSmallImage.src,
                  position: 'top',
                }}
              >
                <CardHolder>
                  <CardWrapper>
                    {tileProduct && (
                      <ProductTile
                        {...omit(['url', 'content'], tileProduct)}
                        name={tileProduct.name}
                        nameVariant="h5"
                        additionalDetails={tileProduct.additionalDetails}
                        ctaText={tileProduct.ctaText}
                        secondaryCta={{ link: constants.URLS.SALES.FIND_HEALTH_INSURANCE, text: 'Find something else' }}
                        ctaVariant={['purple']}
                        linkTo={tileProduct.url}
                        blurb={blurb}
                        key={tileProduct.id}
                        id={tileProduct.id}
                        isLoadingPrice={isLoadingPrice}
                        price={{
                          ...tileProduct.price,
                          props: {
                            variant: 'extraLargeText',
                          },
                        }}
                        showBoxShadow
                      />
                    )}
                  </CardWrapper>
                </CardHolder>
              </StyledBackgroundImage>
            </Col>
          </StyledRow>
          <HeroPriceDisclaimer product={lastViewedProduct} />
        </Section>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  customer: selectCustomer(state),
  isLoadingPrice: state.price.loading,
  prices: state.price.data,
  useFutureEffectiveDate: state.price.useFutureEffectiveDate,
});

export default connect(mapStateToProps)(WelcomeBackHeroSection);
